import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import DownloadEntry from "../components/download-entry"

const DownloadIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const fileGroup = data.allFile.group
  const markdown = data.allMarkdownRemark.edges

  const getMarkdown = key => {
    const edge = markdown.find(
      ({ node }) => node.parent.relativeDirectory === key
    )
    return edge.node
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Downloads" />
      {fileGroup.sort((a, b) => a.version > b.version ? -1 : 1).map(file => {
        file.markdown = getMarkdown(file.version);
        return (
          <DownloadEntry key={file.version} file={file}></DownloadEntry>
        )
      })}
    </Layout>
  )
}

export default DownloadIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "downloads"}, extension: {in: ["zip", "dmg"]}}) {
      group(field: relativeDirectory) {
        version: fieldValue
        edges {
          node {
            publicURL
            base
            size
            prettySize
            extension
          }
        }
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/notes/"}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          html
          excerpt
          parent {
            ... on File {
              relativeDirectory
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            codename
          }
        }
      }
    }
  }
`
