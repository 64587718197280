import React, { useState } from "react"
import { rhythm, scale } from "../utils/typography"
import { markdown, linkButton } from "./download-entry.module.scss"

const DownloadEntry = ({ file }) => {
  const [showFullText, setShowFullText] = useState(false)

  const version = file.version
  const md = file.markdown
  return (
    <article>
      <header>
        <h2
          style={{
            marginTop: rhythm(1),
            marginBottom: 0,
          }}
        >
          {version} - {md.frontmatter.codename}
        </h2>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
          }}
        >
          {md.frontmatter.date}
        </p>
      </header>

      {!showFullText && (
        <div>
          <p
            className={markdown}
            dangerouslySetInnerHTML={{ __html: md.excerpt }}
          ></p>
          <button className={linkButton} onClick={() => setShowFullText(true)}>
            <span>Tell me more</span>
            <hr></hr>
          </button>
        </div>
      )}

      <div hidden={!showFullText}>
        <table>
          <tbody>
            {file.edges.map(({ node }) => {
              return (
                <tr key={node.publicURL}>
                  <td>
                    <a href={node.publicURL} download>{node.base}</a>
                  </td>
                  <td>{node.prettySize}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div
          className={markdown}
          dangerouslySetInnerHTML={{ __html: md.html }}
        ></div>
        <button className={linkButton} onClick={() => setShowFullText(false)}>
          <span>tl;dr</span>
          <hr></hr>
        </button>
      </div>

    </article>
  )
}

export default DownloadEntry
